/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "styled-components"
import { theme } from "./src/styles/theme"
import GlobalStyle from "./src/styles/globalStyle"

// load font awesome css for icons in css
import "@fortawesome/fontawesome-pro/css/light.css"

// inject #portal-root div before end body tag
const portal = document.createElement("div")
portal.id = "portal-root"
document.body.appendChild(portal)

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle theme={theme} />
    {element}
  </ThemeProvider>
)

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (!(`IntersectionObserver` in window)) {
//     import(`intersection-observer`)
//     console.log(`IntersectionObserver is polyfilled!`)
//   }
// }
