// const FONT_SIZE = "16px"

export const theme = {
  colors: {
    light: "#ffffff",
    dark: "#2d2a27",
    green: "#9cce67",
    darkGreen: "#318440",
  },
  fonts: {
    minSize: "16px",
    maxSize: "24px",
    header: '"Playfair Display", serif',
    body: '"Nunito Sans", sans-serif',
  },
  //   mixins: {
  //     fontSize: fontSize => `
  //         font-size: ${fontSize};
  //         font-size: ${(fontSize / FONT_SIZE) * "1rem"};
  //     `,
  //   },
}
