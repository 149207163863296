import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

const GlobalStyle = createGlobalStyle` 
    ${normalize} 

    html {
        box-sizing: border-box;

        /* font-size: 16px; */

        /* @media screen and (min-width: 320px) {
            font-size: calc(16px + 6 * ((100vw - 320px) / 680));
        }
        @media screen and (min-width: 1000px) {
            font-size: calc(16px * 1.5);
        }  */

        /* font-size: 16px;
        
        @media (min-width: 640px) and (max-width: 1439px) { 
            font-size: calc(10px + (16 - 10) * (100vw - 640px) / (1439 - 640));
        }

        @media (min-width: 1440px) {
            font-size: 16px;
        } */

        /* font-size: 60%; 
        @media only screen and (min-device-width: 480px) {
            font-size: 80%;
        }
        @media only screen and (min-device-width: 768px) {
            font-size: 100%;
        } */
    }

    *, *:before, *:after { 
        box-sizing: inherit;  
    }  
    
    body { 
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;  
        color: ${({ theme }) => theme.colors.dark};
        font-family: ${({ theme }) => theme.fonts.body};
        font-size: 1rem; 
        /* font-size: calc(0.75em + 1vmin); */
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: ${({ theme }) => theme.fonts.header};
        font-weight: 600;
    } 

    h1, .h1 {
        font-size: 3.125rem;
        line-height: 3.75rem;
        font-size: calc(1.5rem + 1vw);  
        line-height: calc(2rem + 1vw);
        margin: 0.6em 0; 

        @media screen and (min-device-width: 768px) {
            font-size: 3.125rem;
            line-height: 3.75rem;
        }
    }

    h2, .h2 {
        font-size: 2.1875rem;
        line-height: 2.8125rem;
        /* margin: 0.5em 0; */
    }

    h3, .h3 {
        font-size: 1.6875rem;
        line-height: 2.3125rem;
        /* margin: 0.5em 0; */
    }

    h4, .h4 {
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 1.875rem;
        /* margin: 0.5em 0; */
    }

    h5, .h5 {
        font-size: 0.875rem;
        line-height: 1.5rem;
        /* margin: 0.5em 0; */
    }

    p {
        font-size: 1.125rem;
        line-height: 1.75rem; 
        /* margin: 0.5em 0; */
    }

    ul,
    ol {
        li {
            font-size: 1.125rem;
            line-height: 1.75rem; 
        }
    } 

    a,
    button {
        cursor: pointer;
    }

    a:not(.button) { 
        color: ${({ theme }) => theme.colors.darkGreen}; 
        text-decoration: none; 
        
        &:hover, 
        &:focus {
            text-decoration: underline; 
        } 
    }

    .lead {
        font-family: ${({ theme }) => theme.fonts.header};
        font-size: 1.25rem;
        font-weight: 600;
    }

    strong {
        font-weight: bold;
    }

    .break-long-words {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
        hyphens: auto;
    }

    .accent {
        position: relative;
        height: 1px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
        }

        &:before {
            left: -100%;
            width: 100%;
            height: 1px;
            background-color: ${({ theme }) => theme.colors.green};
        }

        &:after {
            left: 0;
            width: 90px;
            height: 9px;
            background-color: ${({ theme }) => theme.colors.green};
        }

        &.accent--reverse {
            &:before {
                left: 0;
                width: 9999%;
                height: 1px;
                background-color: ${({ theme }) => theme.colors.green};
            }
        }
    }

    .button { 
        color: ${({ theme }) => theme.colors.dark};
        font-family: ${({ theme }) => theme.fonts.header};
        font-size: 1.0625rem;
        /* font-size: calc(1.0625rem - 1vw);   */
        font-weight: 400;
        line-height: 0.975rem;
        text-decoration: none;
        position: relative;
        display: inline-block;
        margin: 0.5rem;
        padding: 18px 40px;
        background-color: ${({ theme }) => theme.colors.green}; 
        border: none;
        border-radius: 25px;
        transition: 0.1s background-color linear, 0.1s border-color linear, 0.1s color linear;
        overflow: hidden;
        outline: 0;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.08);
            transition: 0.1s opacity linear;
            opacity: 0;
        }

        &:hover,
        &:focus {
            color: ${({ theme }) => theme.colors.light};
            &:after {
                opacity: 1;
            }
        }
    }

    .link { 
        color: ${({ theme }) => theme.colors.green};
        font-family: ${({ theme }) => theme.fonts.header};
        font-weight: normal;
        text-decoration: none; 
        vertical-align: middle;
        display: inline-block;
        /* transition: all 500ms ease; */

        &:hover, 
        &:focus {
            text-decoration: underline; 
        } 

        [class*=fa-] {
            margin-left: 4px;
        }
    }

    .form {
        font-weight: 300;
        font-size: 1rem;
        position: relative;
        max-width: 600px;
        margin: 0 auto;

        .form__field-group {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -.5rem;

            .form__field {
                width: calc(50% - 1rem);
                margin: 0 .5rem 0.9375rem;
            }

            @media (max-width: 480px) {
                display: block;
                margin: 0;

                .form__field {
                    width: 100%;
                    margin: 0 0 0.9375rem;
                }
            }
        }

        .form__field {
            margin: 0 0 0.9375rem;

            &.form__field--hidden {
                display: none;
            }
        }

        .form__required {
            color: red;
        }

        .form__radio-group {
            display: flex;
            flex-wrap: wrap;

            > label {
                margin: 0.5rem 0;
                margin-right: 2rem;
                cursor: pointer;

                [type=radio] {
                    margin-right: 8px;
                }
            }
        }

        .form__divider {
            width: 100%;
            height: 1px;
            margin: 1.5rem 0;
            background-color: rgba(255, 255, 255, 0.5);
        }

        input:not([type="radio"]) {
            width: 100%;
            max-width: 100%; 
            padding: 10px 20px;
            border: 1px solid #ccc;
            background: #fafafa;
            border-radius: 2px;
        }

        input[type=checkbox] {
            width: auto;
            margin-right: 8px;
        }

        textarea {
            width: 100%;
            max-width: 100%; 
            height: 150px;
            padding: 10px 20px;
            /* margin: 6px 0 4px; */
            border: 1px solid #ccc;
            background: #fafafa;
            border-radius: 2px;
        }

        .form__message {
            color: ${({ theme }) => theme.colors.dark};
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 400px;
            padding: 60px 40px;
            background: white;
            box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);

            h1, h2,h3,h4,h5,h6 {
                margin: 0 0 1rem;
            }

            p {
                margin: 0;
            }
        }
    }

    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;

        &:focus {
            background-color: #eee;
            clip: auto !important;
            clip-path: none;
            color: #444;
            display: block;
            font-size: 1em;
            height: auto;
            left: 5px;
            line-height: normal;
            padding: 15px 23px 14px;
            text-decoration: none;
            top: 5px;
            width: auto;
            z-index: 100000; /* Above WP toolbar. */
        }
    }
    
    main > section:first-child:not([class*=InventorySlider__]) { 
        padding-top: 120px;

        @media screen and (max-width: 1024px) {
            padding-top: 100px; 
        }
    } 

    main > [class*=InventorySlider__]:first-child {
        margin-top: 120px;

        @media screen and (max-width: 1024px) {
            margin-top: 100px; 
        }
    }

    /* Global due only to how popups work in the DOM */
    .member-pop-up-tingle-modal.tingle-modal {
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;

        .tingle-modal-box {
            width: 80%;
            max-width: 1280px;
            border-radius: 0;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        .tingle-modal-box__content {
            padding: 0;
        }

        .member-pop-up {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 3rem;
            overflow-x: hidden;

            .pop-up-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                border: none;
                background-color: transparent;
                color: ${({ theme }) => theme.colors.green};
                font-size: 1.4rem;
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: ${({ theme }) => theme.colors.darkGreen};
                }
            }

            .member-image-column {
                padding-top: 2.5rem;
                padding-right: 3rem;
                flex: 0 1 280px;
            }

            .member-info-column {
                flex: 1 1 400px;
                width: 100%;
                
                h3 {
                    font-size: 1.65rem;
                    color: ${({ theme }) => theme.colors.darkGreen};
                    margin-bottom: 0.5rem;
                }

                p {
                    font-size: 1.05rem;
                    line-height: 1.55;
                }
            }

            .member-info-bar {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: center;

                .info-row {
                    flex: 0 1 auto;
                    padding: 1rem 2rem 0 0;

                    a {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            text-decoration: none;
                            color: ${({ theme }) => theme.colors.darkGreen};
                        }

                        &:hover span {
                            text-decoration: underline;
                        }

                        span {
                            color: #000;
                            font-size: 1.1rem;
                            transition: all 0.2s ease-in-out;
                            white-space: nowrap;
                        }

                        svg {
                            font-size: 1.1rem;
                            padding-right: 0.3rem;
                        }
                    }
                }
            }
        }
    }
`

export default GlobalStyle
